import { withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62644353"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "flex justify-between build-success-message align-center"
}
const _hoisted_2 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.is_shown)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createTextVNode(" Compiled successfully "),
        (!$setup.live_reload)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "ml-4 text-white underline",
              href: "/",
              onClick: _withModifiers($setup.reload, ["prevent"])
            }, " Refresh ", 8 /* PROPS */, _hoisted_2))
          : _createCommentVNode("v-if", true)
      ]))
    : _createCommentVNode("v-if", true)
}